import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Datetime,
  HelpContent,
  HelpLabel,
  HelpTooltip,
  Modal,
  PaginationControl,
  usePaginationUrl,
} from '@virtidev/toolbox';
import FlatButton from '@core/components/FlatButton/FlatButton';
import { usePageScroll } from '@core/components/Page';
import {
  DeletedUserText,
  HelpLegend,
  StyledHeatmapButtonWrapper,
  StyledInfoColumn,
  StyledInfoDetails,
  StyledLogColumn,
  StyledLogEventAndTime,
  StyledScore,
  StyledSimLog,
  StyledTimeStamp,
  StyledUserName,
  UserContainer,
} from '@core/components/Analytics/Simulation/components/SimulationLog/SimulationLog.styled';
import { getCircle, getGreyCircle } from '@base/utility/SimLogCircles';
import SimLogEventBox from '@base/pages/SimLogEventBox';
import { GetFormattedTime } from '@base/utility/TimeFormatting';
import { useSimulationCache } from '@core/components/Analytics/Simulation/AnalyticsSimulation.query';
import { useParams } from 'react-router-dom';
import SimulationLogHeatmaps from '@core/components/Analytics/Simulation/components/SimulationLog/components/SimulationLogHeatmaps';
import LearnerFeedbackForm from '@core/components/Analytics/components/LearnerFeedbackForm/LearnerFeedbackForm';

const PAGE_SIZE = 100;

const getEventName = (type, finished) => {
  switch (type) {
    case 'END_SIM':
      return finished ? 'Finished Simulation' : 'Exited Simulation';
    default:
      return type
        .replace('_', ' ')
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
        .join(' ');
  }
};

const SimulationLog = (props) => {
  const { scrollTop } = usePageScroll();
  const { controlProps } = usePaginationUrl({
    key: 'logPage',
    onChange: scrollTop,
    pageSize: PAGE_SIZE,
  });
  const answer = (props.data?.AnalyticsEvents?.nodes || []).find(
    (event) => event.Type === 'ANSWER'
  );

  console.log(props);

  const { simulationId } = useParams();
  const [{ hasHeatmaps }, isLoading] = useSimulationCache(simulationId);

  // this parses events mainly to split ANSWER into individual events: 'Question' and 'Answer',
  // and adds things like media data only when necessary so event boxes can be conditionally rendered based on props
  const parsedAnalyticsEvents = useMemo(() => {
    return (props.data?.AnalyticsEvents?.nodes || []).reduce((carry, event) => {
      if (event.Type === 'ANSWER') {
        const questionEvent = {
          type: event.Event.Type,
          title: event.Event.Description || event.Event.Title,
          timeSpent: event.Event.TimeSpent,
          ID: parseInt(event.Event.ID) + event.TimeSpent,
        };
        const answerEvent = {
          type: 'Answer',
          quiz: event.Event.QuestionType !== 'no_scoring',
          multipleChoice: event.Event.QuestionType === 'multiple_choice',
          answers:
            event.Event.QuestionType === 'multiple_choice'
              ? event.Options.nodes
              : [event.Option],
          options: event.Event.Options.nodes,
          timeSpent: event.TimeSpent,
          ID: parseInt(event.ID),
        };
        carry.push(questionEvent);
        carry.push(answerEvent);
      } else {
        const interactionEvent = {
          type: event.Event.Type || getEventName(event.Type, event.Finished),
          title: event.Event.Description || event.Event.Title,
          timeSpent: event.Event.TimeSpent || event.TimeSpent,
          imageURL: event.Event.ThumbnailURL,
          videoURL: event.Event.VideoDownloadLowURL,
          audioURL: event.Event.AudioURL,
          action: event.Event.Action,
          ID: parseInt(event.ID),
          videoTitle: event.Video.Title,
        };
        carry.push(interactionEvent);
      }
      return carry;
    }, []);
  }, [props.data?.AnalyticsEvents?.nodes]);

  const lastEdited = useMemo(() => {
    const end = props.data?.AnalyticsEvents?.nodes.find(
      (event) => event.Type === 'END_SIM' && event.Finished === true
    );
    if (!end) {
      return null;
    }
    return end.Created;
  }, [props.data]);

  const [showHeatmapsModal, setShowHeatmapsModal] = useState(false);
  const handleOpenHeatmaps = useCallback(() => {
    setShowHeatmapsModal(true);
  }, []);
  const handleCloseHeatmaps = useCallback(() => {
    setShowHeatmapsModal(false);
  }, []);

  return (
    <StyledSimLog>
      {props.data && (
        <StyledInfoColumn>
          <UserContainer>
            <StyledUserName>
              {props.userMode === 'admin' ? (
                <>
                  User:{' '}
                  {props.data.Member.Name || (
                    <DeletedUserText>(Deleted)</DeletedUserText>
                  )}
                </>
              ) : (
                <>{props.simTitle}</>
              )}
            </StyledUserName>

            <HelpTooltip placement="bottom-start">
              <HelpContent>
                In {props.userMode === 'admin' ? `a Learner's` : 'this'}{' '}
                Interactive Video Log, you can see every interaction and event
                that has occurred{' '}
                {props.userMode === 'admin' ? `for the Learner` : ''} during{' '}
                {props.userMode === 'admin' ? `their` : 'your'} session.
              </HelpContent>
              <HelpLabel>Legend for Answers:</HelpLabel>
              <HelpLegend>
                <div>Answer selected:</div>
                <div>{getGreyCircle(true, true)}</div>
                <div>Answer not selected:</div>
                <div>{getGreyCircle(false, true)}</div>
                <div>Correct answer:</div>
                <div>{getCircle(true, true)}</div>
                <div>Incorrect answer:</div>
                <div>{getCircle(true, false)}</div>
              </HelpLegend>
            </HelpTooltip>
          </UserContainer>
          <StyledInfoDetails>
            <div>
              <h2>Started:</h2>
              <h2>Finished:</h2>
              {!!answer && <br></br>}
              {props.isQuiz && (
                <>
                  <h2>Completed:</h2>
                  <h2>Score:</h2>
                </>
              )}
              {!!answer && <h2>Points:</h2>}
            </div>
            <div>
              <h2>
                <Datetime datetime={props.data.Created} long />
              </h2>
              <h2>
                <Datetime datetime={lastEdited} long emptyDisplay="N/A" />
              </h2>
              {!!answer && <br></br>}
              {props.isQuiz && (
                <>
                  <h2>{props.data.Finished ? 'Yes' : 'No'}</h2>
                  <h2>{props.data.Score}%</h2>
                </>
              )}
              {!!answer && <h2>{props.data.Points}</h2>}
            </div>
          </StyledInfoDetails>
          {hasHeatmaps && (
            <StyledHeatmapButtonWrapper>
              <FlatButton
                description="Gain insight into user behavior"
                iconName="heatmap"
                onClick={handleOpenHeatmaps}
              >
                View heatmap
              </FlatButton>
            </StyledHeatmapButtonWrapper>
          )}
          <Modal show={showHeatmapsModal} onHide={handleCloseHeatmaps}>
            <SimulationLogHeatmaps
              submissionID={props.data.ID}
              simulationID={simulationId}
            />
          </Modal>
        </StyledInfoColumn>
      )}

      <StyledLogColumn>
        {parsedAnalyticsEvents &&
          parsedAnalyticsEvents.map((event, index) => {
            if (event.type) {
              if (event.type === 'Answer' && event.options.length === 0) {
                return null; // incomplete data is returned from unity when sim is exited early, this is a quick fix
              }
              return (
                <StyledLogEventAndTime key={event.ID}>
                  <SimLogEventBox event={event} />
                  <StyledTimeStamp>
                    {(event.timeSpent > 0 &&
                      GetFormattedTime(event.timeSpent)) ||
                      ''}
                  </StyledTimeStamp>
                </StyledLogEventAndTime>
              );
            } else {
              return null;
            }
          })}
        {props.data && props.isQuiz && props.data.Score > 0 && (
          <StyledScore>
            <label>Final Score: {props.data.Score}%</label>
          </StyledScore>
        )}
        <PaginationControl
          {...controlProps}
          total={props.data?.AnalyticsEvents?.pageInfo?.totalCount}
        />
        <LearnerFeedbackForm
          allowLearnerFeedbackSetting={false}
          analyticsSubmissionId={props.data?.ID}
          createdDate={props.data?.Created}
          memberID={props.data?.MemberID}
          learnerFeedbackRating={props.data?.LearnerFeedbackRating}
        />
      </StyledLogColumn>
    </StyledSimLog>
  );
};

SimulationLog.propTypes = {
  simTitle: PropTypes.string,
  isQuiz: PropTypes.bool.isRequired,
  userMode: PropTypes.oneOf(['user', 'admin']).isRequired,
  data: PropTypes.shape({
    AnalyticsEvents: PropTypes.shape({
      nodes: PropTypes.array,
    }),
  }),
};

export default SimulationLog;
