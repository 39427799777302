import { gql } from '@apollo/client';

export const READ_VIRTUAL_HUMAN_SESSIONS = gql`
  query readVirtualHumanSessions(
    $ID: ID!
    $limit: Int
    $offset: Int
    $filterBy: AnalyticsSubmissionFilterFields
    $sort: AnalyticsSubmissionSortFields
  ) {
    readOneVirtualHuman(filter: { ID: { eq: $ID } }) {
      ID
      Title
      Type
      ExternalID
      HideScoresFromUsers
      AnalyticsSubmissions(
        limit: $limit
        offset: $offset
        filter: $filterBy
        sort: $sort
      ) {
        pageInfo {
          totalCount
          hasNextPage
          hasPreviousPage
        }
        edges {
          node {
            ID
            Finished
            PDAssessmentID
            Score
            Created
            FirstScore
            LatestScore
            ExternalUserID
            LearnerFeedbackRating
            Member {
              Name
              ID
              UserType
            }
            AnalyticsEvents(filter: { Type: { eq: END_SIM } }) {
              edges {
                node {
                  ID
                  TimeSpent
                  PDAssessmentID
                  PDConversationScore
                  PDDiagnosisScore
                  Diagnosis
                  Type
                  Created
                }
              }
            }
          }
        }
      }
    }
  }
`;
