import styled, { css, DefaultTheme } from 'styled-components';

export const Container = styled.div`
  ${({ theme: { spacing, color } }) => css`
    display: grid;
    gap: ${spacing.p5};
    justify-items: center;
    text-align: center;
    border-radius: ${spacing.p2};
    border: 1px solid ${color.blue30};
    padding: ${spacing.p6};
  `}
`;

export const Title = styled.h2`
  ${({ theme: { font, color } }) => css`
    ${font.note}
    color: ${color.midnight};
  `}
`;

const getStarSvg = (color) => {
  const escapedColor = color.replace('#', '%23');
  return `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="42" height="40" viewBox="0 0 42 40" fill="none"><path d="M19.6413 1.1815C20.069 -0.134659 21.931 -0.134657 22.3587 1.1815L26.2921 13.2875C26.4834 13.8761 27.0319 14.2746 27.6508 14.2746H40.3797C41.7636 14.2746 42.339 16.0455 41.2194 16.8589L30.9215 24.3408C30.4208 24.7046 30.2113 25.3494 30.4025 25.938L34.336 38.0439C34.7636 39.3601 33.2572 40.4545 32.1376 39.6411L21.8397 32.1592C21.339 31.7954 20.661 31.7954 20.1603 32.1592L9.86236 39.6411C8.74277 40.4545 7.23637 39.3601 7.66402 38.0439L11.5975 25.938C11.7887 25.3494 11.5792 24.7046 11.0785 24.3408L0.780581 16.8589C-0.339009 16.0455 0.236387 14.2746 1.62028 14.2746H14.3492C14.9681 14.2746 15.5166 13.8761 15.7079 13.2875L19.6413 1.1815Z" fill="${escapedColor}"/></svg>')`;
};

export const StarButton = styled.button`
  ${
    /** @param {{ theme: DefaultTheme, $filled?: boolean }} p */
    ({ theme: { color, spacing }, $filled }) => css`
      background: white;
      position: relative;
      width: 65px;
      height: 65px;
      border: 1px solid white;
      border-radius: 50%;
      padding: 0;

      &:not([disabled]) {
        cursor: pointer;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: ${getStarSvg(
          $filled ? color.amber : color.midnight30
        )};
        background-repeat: no-repeat;
        background-position: center;
      }

      &:not([disabled]):hover:after,
      &:not([disabled]):focus:after,
      &:not([disabled]):has(~ :hover)::after,
      &:not([disabled]):has(~ :focus):after {
        background-image: ${getStarSvg(color.amber)};
      }

      &:not([disabled]):hover {
        background-color: ${color.amber10};
      }

      &:not([disabled]):focus-visible {
        border-color: ${color.amber};
      }
    `
  }
`;

export const Actions = styled.div`
  ${({ theme: { spacing } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 65px;
  `}
`;

export const ButtonHolder = styled.div`
  ${({ theme: { spacing } }) => css`
    display: flex;
    gap: ${spacing.p2};
  `}
`;
