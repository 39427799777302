import { gql } from '@apollo/client';

export const UPDATE_ANALYTICS_SUBMISSION = gql`
  mutation updateAnalyticsSubmission($Input: UpdateAnalyticsSubmissionInput!) {
    updateAnalyticsSubmission(input: $Input) {
      ID
      LearnerFeedbackRating
    }
  }
`;
