import { gql, useApolloClient, useQuery } from '@apollo/client';
import { useMemo } from 'react';

/**
 * @typedef {import('../../../models/simulation.types').Simulation} Simulation
 */

export const SIMULATION_TITLE = gql`
  query simulationTitle($ID: ID!) {
    readOneSimulation(filter: { ID: { eq: $ID } }) {
      ID
      Title
      Quiz
      hasHeatmaps
      OrganisationID
      AnalyticsLastUpdated
    }
  }
`;

export const SIMULATION_FRAGMENT = gql`
  fragment simulationTitle on Simulation {
    ID
    Title
    Quiz
    hasHeatmaps
    OrganisationID
    AnalyticsLastUpdated
  }
`;

/**
 * Tries to fetches title from cache first, otherwise will fetch from server and cache it
 *
 * @param {string} id
 * @returns {[Partial<Simulation>, boolean]}
 */
export const useSimulationCache = (id) => {
  const client = useApolloClient();
  const cacheData = client.readFragment({
    id: `Simulation:${id}`,
    fragment: SIMULATION_FRAGMENT,
  });

  const hasFields = [
    'Title',
    'hasHeatmaps',
    'Quiz',
    'OrganisationID',
    'AnalyticsLastUpdated',
  ].every((field) => cacheData?.[field] != null);

  const { data, loading } = useQuery(SIMULATION_TITLE, {
    variables: { ID: id },
    fetchPolicy: 'cache-first',
    skip: hasFields,
  });

  const Title = useMemo(
    () => cacheData?.Title || data?.readOneSimulation?.Title,
    [cacheData, data]
  );

  const hasHeatmaps = useMemo(
    () => cacheData?.hasHeatmaps || data?.readOneSimulation?.hasHeatmaps,
    [cacheData, data]
  );

  const Quiz = useMemo(
    () => cacheData?.Quiz || data?.readOneSimulation?.Quiz,
    [cacheData, data]
  );

  const OrganisationID = useMemo(
    () => cacheData?.OrganisationID || data?.readOneSimulation?.OrganisationID,
    [cacheData, data]
  );

  const AnalyticsLastUpdated = useMemo(
    () =>
      cacheData?.AnalyticsLastUpdated ||
      data?.readOneSimulation?.AnalyticsLastUpdated,
    [cacheData, data]
  );

  return [
    { Title, hasHeatmaps, Quiz, OrganisationID, AnalyticsLastUpdated },
    loading,
  ];
};

export const SIMULATION_ANALYTICS_ENGAGEMENT = gql`
  query simulationAnalyticsEngagement(
    $ID: ID!
    $StartDate: String
    $EndDate: String
    $GroupID: ID
    $UserType: String
    $leaderboardFilter: AnalyticsSubmissionFilterFields
    $mostRecentFilter: AnalyticsSubmissionFilterFields
  ) {
    readOneSimulation(filter: { ID: { eq: $ID } }) {
      ID
      Title
      Quiz
      Videos(limit: 1) {
        nodes {
          ID
        }
      }
      leaderboard: AnalyticsSubmissions(
        filter: $leaderboardFilter
        limit: 10
        sort: { Points: DESC }
      ) {
        edges {
          node {
            ID
            Created
            Points
            Quiz
            Score
            Member {
              ID
              Name
            }
          }
        }
      }
      mostRecent: AnalyticsSubmissions(
        filter: $mostRecentFilter
        limit: 10
        sort: { Created: DESC }
      ) {
        edges {
          node {
            ID
            Created
            Points
            Quiz
            Score
            Member {
              ID
              Name
            }
          }
        }
      }
    }
    simulationAnalytics(
      SimulationID: $ID
      StartDate: $StartDate
      EndDate: $EndDate
      GroupID: $GroupID
      UserType: $UserType
    ) {
      TotalViews
      TotalCompletions
      AverageTimeWatched
      DailyTotalViewsDays
      DailyTotalCompletedSessions
      LearnerFeedbackSummary {
        TotalResponses
        FiveStarResponses
        FourStarResponses
        ThreeStarResponses
        TwoStarResponses
        OneStarResponses
        AverageRating
      }
    }
  }
`;

export const SIMULATION_ANALYTICS_PERFORMANCE = gql`
  query simulationAnalyticsPerformance(
    $ID: ID!
    $StartDate: String
    $EndDate: String
    $GroupID: ID
    $UserType: String
  ) {
    simulationAnalytics(
      SimulationID: $ID
      StartDate: $StartDate
      EndDate: $EndDate
      GroupID: $GroupID
      UserType: $UserType
    ) {
      TotalCompletions
      AverageScore
      AverageReactionTime
      AverageScoreFirst
      AverageScoreLast
      EffortResultData
    }
  }
`;

export const SIMULATION_ANALYTICS_INTERACTIVITY = gql`
  query simulationAnalyticsInteractivity(
    $ID: ID!
    $StartDate: String
    $EndDate: String
    $GroupID: ID
    $UserType: String
    $clickFilters: AnalyticsEventFilterFields
    $questionFilters: AnalyticsEventFilterFields
  ) {
    readOneSimulation(filter: { ID: { eq: $ID } }) {
      ID
      EventClicks(limit: 10) {
        nodes {
          ID
          Title
          AnalyticsEvents(filter: $clickFilters) {
            pageInfo {
              totalCount
            }
          }
        }
      }
    }
    simulationAnalytics(
      SimulationID: $ID
      StartDate: $StartDate
      EndDate: $EndDate
      GroupID: $GroupID
      UserType: $UserType
    ) {
      TotalCompletions
      Questions {
        ID
        Title
        Description
        QuestionType
        AnalyticsEvents(filter: $questionFilters) {
          pageInfo {
            totalCount
          }
        }
        Options {
          nodes {
            ID
            Answers(filter: $questionFilters) {
              pageInfo {
                totalCount
              }
            }
            MultipleAnswers(filter: $questionFilters) {
              pageInfo {
                totalCount
              }
            }
            Text
            Correct
          }
        }
        ImageMedia {
          ID
          TusID
        }
      }
    }
  }
`;

export const SIMULATION_ANALYTICS_HEATMAPS = gql`
  query simulationAnalyticsHeatmaps($ID: ID!) {
    readOneSimulation(filter: { ID: { eq: $ID } }) {
      ID
      StartVideoID
      Videos {
        nodes {
          ID
          Content360
          Title
          TranscodingStatus
          PosterURL
          EditorURL
        }
      }
    }
  }
`;

export const SIMULATION_LOGS = gql`
  query simulationLogs(
    $ID: ID!
    $limit: Int
    $offset: Int
    $filter: AnalyticsSubmissionFilterFields
    $sort: AnalyticsSubmissionSortFields
  ) {
    readOneSimulation(filter: { ID: { eq: $ID } }) {
      ID
      Title
      Quiz
      AnalyticsSubmissions(
        limit: $limit
        offset: $offset
        filter: $filter
        sort: $sort
      ) {
        pageInfo {
          totalCount
        }
        edges {
          node {
            ID
            Score
            Created
            Finished
            LastEdited
            ExternalUserID
            Member {
              Name
              ID
              UserType
            }
            EndEvent: AnalyticsEvents(
              filter: { Type: { eq: END_SIM }, Finished: { eq: true } }
              limit: 1
            ) {
              nodes {
                ID
                Created
              }
            }
          }
        }
      }
    }
  }
`;

export const SIMULATION_USERS = gql`
  query simulationUsersAndScores($ID: ID!) {
    readOneSimulation(filter: { ID: { eq: $ID } }) {
      ID
      Title
      Quiz
    }
    simulationAnalytics(SimulationID: $ID) {
      SimulationScores {
        ID
        FirstScore
        BestScore
        Member {
          ID
          Name
          LastSeen
          AnalyticsSubmissions(
            filter: { SimulationID: { eq: $ID }, Finished: { eq: true } }
            sort: { Created: ASC }
          ) {
            nodes {
              ID
              Score
            }
          }
        }
      }
    }
  }
`;

export const DELETE_SUBMISSION_LOG = gql`
  mutation deleteAnalyticsSubmissions($IDs: [ID]!) {
    deleteAnalyticsSubmissions(ids: $IDs)
  }
`;
