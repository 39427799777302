import { gql } from '@apollo/client';

export const MY_VIRTUAL_HUMAN_SESSIONS = gql`
  query myVirtualHumanLogs(
    $limit: Int
    $offset: Int
    $filter: AnalyticsSubmissionFilterFields
    $sort: AnalyticsSubmissionSortFields
  ) {
    readAnalyticsSubmissions(
      limit: $limit
      offset: $offset
      filter: $filter
      sort: $sort
    ) {
      nodes {
        ID
        Finished
        PDAssessmentID
        Score
        Created
        FirstScore
        LatestScore
        LastEdited
        LearnerFeedbackRating
        VirtualHuman {
          ID
          Title
          ExternalID
          HideScoresFromUsers
        }
        AnalyticsEvents(filter: { Type: { eq: END_SIM } }) {
          edges {
            node {
              ID
              TimeSpent
              PDAssessmentID
              PDConversationScore
              PDDiagnosisScore
              Diagnosis
              Type
              Created
            }
          }
        }
      }
      pageInfo {
        totalCount
      }
    }
  }
`;
