import { gql, useApolloClient, useQuery } from '@apollo/client';
import { useMemo } from 'react';

export const VIRTUAL_HUMAN_TITLE = gql`
  query virtualHumanTitle($ID: ID!) {
    readOneVirtualHuman(filter: { ID: { eq: $ID } }) {
      ID
      Title
      OrganisationID
    }
  }
`;

export const VIRTUAL_HUMAN_FRAGMENT = gql`
  fragment virtualHuman on VirtualHuman {
    ID
    Title
    OrganisationID
  }
`;

/**
 * Tries to fetches title from cache first, otherwise will fetch from server and cache it
 *
 * @param {ID} id
 * @returns [string|null, boolean]
 */
export const useVirtualHumanTitle = (id) => {
  const client = useApolloClient();
  const cacheData = client.readFragment({
    id: `VirtualHuman:${id}`,
    fragment: VIRTUAL_HUMAN_FRAGMENT,
  });
  const { data, loading } = useQuery(VIRTUAL_HUMAN_TITLE, {
    variables: { ID: id },
    fetchPolicy: 'cache-first',
    skip: cacheData,
  });

  const Title = useMemo(
    () => cacheData?.Title || data?.readOneVirtualHuman?.Title,
    [cacheData, data]
  );

  const OrganisationID = useMemo(
    () =>
      cacheData?.OrganisationID || data?.readOneVirtualHuman?.OrganisationID,
    [cacheData, data]
  );

  return [{ Title, OrganisationID }, loading];
};

export const VIRTUAL_HUMAN_ANALYTICS = gql`
  query virtualHumanAnalytics(
    $ID: ID!
    $VirtualHumanID: ID!
    $StartDate: String
    $EndDate: String
    $UserType: String
    $GroupID: ID
  ) {
    readOneVirtualHuman(filter: { ID: { eq: $ID } }) {
      ID
      Type
      ExternalID
    }
    virtualHumanAnalytics(
      VirtualHumanID: $VirtualHumanID
      StartDate: $StartDate
      EndDate: $EndDate
      UserType: $UserType
      GroupID: $GroupID
    ) {
      TotalViews
      TotalCompletions
      TotalTimeSpent
      AverageTimeSpent
      DailyTotalViewsDays
      DailyTotalViewsViews
      DailyTotalViewsScores
      AverageScore
      AverageScoreFirst
      AverageScoreLast
      AverageConversationScore
      AverageDiagnosisScore
      EffortResultData
      LearnerFeedbackSummary {
        TotalResponses
        FiveStarResponses
        FourStarResponses
        ThreeStarResponses
        TwoStarResponses
        OneStarResponses
        AverageRating
      }
    }
  }
`;

export const VIRTUAL_HUMAN_BASICS = gql`
  query virtualHumanBasics($ID: ID!) {
    readOneVirtualHuman(filter: { ID: { eq: $ID } }) {
      ID
      ExternalID
    }
  }
`;

export const DELETE_SUBMISSION_LOG = gql`
  mutation deleteAnalyticsSubmissions($IDs: [ID]!) {
    deleteAnalyticsSubmissions(ids: $IDs)
  }
`;

export const VIRTUAL_HUMAN_OBJECTIVE_ANALYTICS = gql`
  query (
    $VirtualHumanID: ID!
    $StartDate: String
    $EndDate: String
    $UserType: String
    $GroupID: ID
  ) {
    virtualHumanPDObjectiveAnalytics(
      VirtualHumanID: $VirtualHumanID
      StartDate: $StartDate
      EndDate: $EndDate
      UserType: $UserType
      GroupID: $GroupID
    ) {
      TotalSessions
      PDObjectiveHits {
        PDObjectiveID
        HitCount
      }
    }
  }
`;

export const VIRTUAL_HUMAN_EVALUATION_ANALYTICS = gql`
  query virtualHumanEvaluationAnalytics(
    $VirtualHumanID: ID!
    $StartDate: String
    $EndDate: String
    $UserType: String
    $GroupID: ID
  ) {
    virtualHumanEvaluationAnalytics(
      VirtualHumanID: $VirtualHumanID
      StartDate: $StartDate
      EndDate: $EndDate
      UserType: $UserType
      GroupID: $GroupID
    ) {
      AverageEvaluationScores {
        AverageScore
        PDEvaluationID
        Evaluations {
          ID
          PDEvaluationID
          PDEvaluationReportID
          PDConversationScore
          Member {
            ID
            Name
          }
        }
      }
    }
  }
`;
