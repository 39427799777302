import { ToDecimalPlace } from '@base/utility/NumberFormatting';
import {
  ScorePercentCircleWrapper,
  Percent,
} from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/ScorePercentCircle/ScorePercentCircle.styled';
import React, { FC } from 'react';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import { useTheme } from 'styled-components';

/**
 * @type {FC<{
 *  strokeWidth?: number;
 *  showPercent?: boolean;
 *  scorePercent: number;
 *  children?: React.ReactNode;
 *  circleStyles?: Partial<Parameters<buildStyles>[0]>;
 * }>}
 * */
const ScorePercentCircle = ({
  strokeWidth,
  scorePercent,
  circleStyles,
  showPercent,
}) => {
  const { color } = useTheme();
  return (
    <ScorePercentCircleWrapper>
      <CircularProgressbarWithChildren
        minValue={0}
        maxValue={1}
        value={scorePercent / 100}
        strokeWidth={strokeWidth ?? 8}
        styles={buildStyles({
          strokeLinecap: 'butt',
          textSize: '1.5rem',
          pathColor: color.pink,
          trailColor: color.pink30,
          ...circleStyles,
        })}
      >
        {showPercent && <Percent>{ToDecimalPlace(scorePercent, 2)}%</Percent>}
      </CircularProgressbarWithChildren>
    </ScorePercentCircleWrapper>
  );
};

export default ScorePercentCircle;
