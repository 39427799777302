import styled, { css } from 'styled-components';

export const ScorePercentCircleWrapper = styled.div`
  width: 154px;
  height: 154px;
`;

export const Percent = styled.div`
  ${({
    theme: {
      color,
      font: { h1 },
    },
  }) => css`
    ${h1}
    color: ${color.midnight5};
  `}
`;
