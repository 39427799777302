import useUser from '@core/helpers/useUser';
import {
  StarButton,
  Container,
  Title,
  ButtonHolder,
  Actions,
} from './LearnerFeedbackForm.styled';
import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_ANALYTICS_SUBMISSION } from '@core/components/Analytics/components/LearnerFeedbackForm/LearnerFeedbackForm.query';
import { Button, Loading } from '@virtidev/toolbox';

/**
 * @type {React.FC<{
 *  allowLearnerFeedbackSetting?: boolean;
 *  learnerFeedbackRating?: number;
 *  analyticsSubmissionId: number;
 *  memberID: string;
 *  createdDate: string | null;
 * }>}
 */
export const LearnerFeedbackForm = ({
  allowLearnerFeedbackSetting,
  analyticsSubmissionId,
  createdDate,
  memberID,
  learnerFeedbackRating,
}) => {
  const { ID } = useUser();
  const [rating, setRating] = useState(0);

  const [updateAnalyticsSubmission, { data, loading, error }] = useMutation(
    UPDATE_ANALYTICS_SUBMISSION,
    {
      variables: {
        Input: {
          ID: analyticsSubmissionId,
          LearnerFeedbackRating: rating,
        },
      },
      onCompleted: () => {
        setRating(0);
      },
    }
  );

  const rateOne = useCallback(() => {
    setRating(1);
  }, [setRating]);

  const rateTwo = useCallback(() => {
    setRating(2);
  }, [setRating]);

  const rateThree = useCallback(() => {
    setRating(3);
  }, [setRating]);

  const rateFour = useCallback(() => {
    setRating(4);
  }, [setRating]);

  const rateFive = useCallback(() => {
    setRating(5);
  }, [setRating]);

  const handleSubmit = useCallback(
    (e) => {
      updateAnalyticsSubmission();
    },
    [updateAnalyticsSubmission]
  );

  const handleCancel = useCallback(
    (e) => {
      setRating(0);
    },
    [setRating]
  );

  if (!analyticsSubmissionId) {
    return null;
  }

  const submittedRating =
    learnerFeedbackRating ||
    data?.updateAnalyticsSubmission.LearnerFeedbackRating;

  const analyticsSubmissionMemberID =
    memberID;

  const readOnly =
    // submittedRating ||
    !allowLearnerFeedbackSetting ||
    analyticsSubmissionMemberID !== ID ||
    (createdDate &&
      new Date(createdDate).getTime() <
        new Date().getTime() - 1000 * 60 * 60 * 24 * 7);

  const getTitle = () => {
    if (submittedRating) {
      return 'Feedback submitted!';
    }
    if (readOnly) {
      return 'Feedback not submitted';
    }
    return 'How effective was this training?';
  };

  return (
    <Container>
      <Title>{getTitle()}</Title>
      <Actions>
        {loading && <Loading />}
        {!loading && !rating && (
          <div>
            <StarButton
              disabled={readOnly}
              onClick={rateOne}
              $filled={submittedRating >= 1}
            >
              1
            </StarButton>
            <StarButton
              disabled={readOnly}
              onClick={rateTwo}
              $filled={submittedRating >= 2}
            >
              2
            </StarButton>
            <StarButton
              disabled={readOnly}
              onClick={rateThree}
              $filled={submittedRating >= 3}
            >
              3
            </StarButton>
            <StarButton
              disabled={readOnly}
              onClick={rateFour}
              $filled={submittedRating >= 4}
            >
              4
            </StarButton>
            <StarButton
              disabled={readOnly}
              onClick={rateFive}
              $filled={submittedRating >= 5}
            >
              5
            </StarButton>
          </div>
        )}
        {!loading && !!rating && (
          <ButtonHolder>
            <Button onClick={handleCancel} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="turquoise">
              Submit {rating} ★ rating
            </Button>
          </ButtonHolder>
        )}
      </Actions>
    </Container>
  );
};

export default LearnerFeedbackForm;
