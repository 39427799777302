import React, { FC, useCallback } from 'react';
import * as Styled from './ScoreOverview.styled';
import CoachingSessionReportGenerator from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/CoachingSessionReportGenerator/CoachingSessionReportGenerator';
import ObjectiveGroupResult from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/ObjectiveGroupResult/ObjectiveGroupResult';
import CoachingSessionReport from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/CoachingSessionReport/CoachingSessionReport';
import { ToDecimalPlace } from '@base/utility/NumberFormatting';
import { clamp } from 'lodash';
import ScoreStats from '@core/components/Analytics/VirtualHuman/ScoreOverview/components/ScoreStats/ScoreStats';
import ProgressLoader from '@core/components/Loaders/ProgressLoader/ProgressLoader';
import { Button } from '@virtidev/toolbox';
import useGetVHHasEvaluations from '@base/utility/VHHooks/API/useGetVHHasEvaluations';
import LearnerFeedbackForm from '@core/components/Analytics/components/LearnerFeedbackForm/LearnerFeedbackForm';

/**
 * @typedef {import('../../../../models/vh-server/vh-server-objective.types').VHServerObjectiveGroup} VHServerObjectiveGroup
 * @typedef {import('../../../../models/vh-server/vh-server-objective.types').VHServerObjective} VHServerObjective
 * @typedef {import('../../../../models/vh-server/vh-server-virtualhuman.types').VHServerVirtualHuman} VHServerVirtualHuman
 * @typedef {import('../../../../models/vh-server/vh-server-coaching-session-report.types').CoachingSessionReport} CoachingSessionReport
 * @typedef {import('../../../../models/vh-server/vh-server-coaching-session-report.types').CoachingSessionReportObjective} CoachingSessionReportObjective
 * @typedef {import('@core/models/virtualhuman.types').VirtualHuman} VirtualHuman
 * @typedef {import('@core/models/vh-server/vh-server-assessment.types').HitObjectives} HitObjectives
 *
 */

/**
 * @type { FC<{
 *    objectiveGroups: VHServerObjectiveGroup[];
 *    allObjectives: VHServerObjective[];
 *    timeSpent: number;
 *    diagnosisSuccess: boolean;
 *    vhDBVH:VHServerVirtualHuman;
 *    ssVH: VirtualHuman;
 *    hitObjectivesData: HitObjectives;
 *    pointsScored: number;
 *    pointsPossible: number;
 *    assessmentId: number;
 *    hideScores: boolean;
 *    easyMode: boolean;
 *    shownHintObjectiveIds: number[];
 *    footer?: React.ReactNode;
 *    sessionReport?: CoachingSessionReport & {objectiveReports: CoachingSessionReportObjective[];}
 *    BCP47Code?: string;
 *    showRegenerateButton?: boolean;
 *    shareToken?: string;
 *    generationProgress?: number;
 *    currentlyGeneratingReport?: boolean
 *    onRegenerate: () => void;
 *    allowedToGenerateReport?: boolean;
 *    allowLearnerFeedbackSetting: boolean;
 *    memberID: string;
 *    learnerFeedbackRating?: number;
 *    analyticsSubmissionId: number;
 *    createdDate: string | null;
 * }>}
 */
const ScoreOverview = ({
  objectiveGroups,
  allObjectives,
  timeSpent,
  onRegenerate,
  diagnosisSuccess,
  vhDBVH,
  ssVH,
  hitObjectivesData,
  pointsScored,
  pointsPossible,
  assessmentId,
  hideScores,
  easyMode,
  shownHintObjectiveIds,
  footer,
  sessionReport,
  BCP47Code,
  showRegenerateButton,
  shareToken,
  currentlyGeneratingReport,
  generationProgress,
  memberID,
  allowLearnerFeedbackSetting,
  learnerFeedbackRating,
  analyticsSubmissionId,
  createdDate,
}) => {
  const generating =
    currentlyGeneratingReport ||
    (sessionReport && sessionReport.generatingStatus === 'pending')
      ? true
      : false;

  // const timeSuccess =
  //   timeSpent < ssVH.TimeLimit * 60 * 1000 || ssVH.TimeLimit === 0;
  let scorePercentageFraction = !pointsScored
    ? 0
    : pointsScored / pointsPossible;
  if (ssVH.Type === 'freeform_v3') {
    scorePercentageFraction = sessionReport?.score
      ? sessionReport?.score / 100
      : 0;
  }
  const clampedScorePercentageFraction = clamp(scorePercentageFraction, 0, 1);
  const scorePercentage = ToDecimalPlace(
    clampedScorePercentageFraction * 100,
    1
  );

  const handleRegenerateClick = useCallback(
    (e) => {
      e.stopPropagation();
      onRegenerate();
    },
    [onRegenerate]
  );

  const showScoreStats =
    (ssVH.Type === 'freeform_v3' &&
      sessionReport?.generatingStatus === 'complete' &&
      !generating) ||
    ssVH.Type !== 'freeform_v3';

  const { hasEvaluations } = useGetVHHasEvaluations(vhDBVH.id);

  const generateButtonVisible =
    !sessionReport &&
    !generating &&
    ((['medical', 'default'].includes(ssVH.Type) && pointsPossible > 0) ||
      (ssVH.Type === 'freeform_v3' && hasEvaluations));

  const msToAllowBeforeRetry = 1000 * 60 * 1;
  const needReportGenerationRetry =
    !currentlyGeneratingReport &&
    (sessionReport?.generatingStatus === 'failed' ||
      (sessionReport?.generatingStatus === 'pending' &&
        new Date().getTime() - new Date(sessionReport.createdAt).getTime() >
          msToAllowBeforeRetry));
  return (
    <Styled.ScoreOverviewWrapper>
      <Styled.ScoreOverviewContent>
        <div>
          {/* time and diagnosis results */}
          {/* <Styled.SummaryText>Performance Report</Styled.SummaryText> */}
          {showScoreStats && (
            <Styled.MainSummary
              $vhType={ssVH.Type}
              $pointsPossible={pointsPossible}
              $disableDiagnosis={vhDBVH.disableDiagnosis}
            >
              <ScoreStats
                pointsPossible={pointsPossible}
                vhType={ssVH.Type}
                clampedScorePercentageFraction={clampedScorePercentageFraction}
                hideScores={hideScores}
                pointsScored={pointsScored}
                scorePercentage={scorePercentage}
                timeSpent={timeSpent}
              />
            </Styled.MainSummary>
          )}
          {/* conversation objectives results */}
          {objectiveGroups.map((objectiveGroup) => (
            <ObjectiveGroupResult
              key={objectiveGroup.id}
              heading={objectiveGroup.name}
              allObjectives={allObjectives}
              hitObjectives={hitObjectivesData?.objectives ?? []}
              feedbackText={objectiveGroup.feedbackText ?? ''}
              easyMode={easyMode}
              shownHintObjectiveIds={shownHintObjectiveIds}
              groupId={objectiveGroup.id}
            />
          ))}
        </div>
        {generating && !needReportGenerationRetry && (
          <Styled.FakeProgressWrapper>
            <ProgressLoader
              progress={generationProgress ?? 0}
              text="Analyzing performance..."
            />
          </Styled.FakeProgressWrapper>
        )}
        {needReportGenerationRetry && (
          <Styled.FailedWrapper>
            <Styled.FailedText>
              The last report generation attempt failed.
            </Styled.FailedText>
            <Button
              design="outline"
              color="turquoise"
              onClick={handleRegenerateClick}
            >
              Retry
            </Button>
          </Styled.FailedWrapper>
        )}
        {generateButtonVisible && (
          <CoachingSessionReportGenerator
            loading={generating}
            onGenerateClick={handleRegenerateClick}
          />
        )}
        {sessionReport &&
          sessionReport.generatingStatus === 'complete' &&
          !generating && (
            <CoachingSessionReport
              showRegenerateButton={showRegenerateButton}
              report={sessionReport}
              BCP47Code={BCP47Code}
              onRegenerateClick={handleRegenerateClick}
            />
          )}
        <Styled.LearnerFeedbackWrapper>
          <LearnerFeedbackForm
            analyticsSubmissionId={analyticsSubmissionId}
            createdDate={createdDate}
            memberID={memberID}
            learnerFeedbackRating={learnerFeedbackRating}
            allowLearnerFeedbackSetting={allowLearnerFeedbackSetting}
          />
        </Styled.LearnerFeedbackWrapper>
      </Styled.ScoreOverviewContent>
      {footer && <Styled.Footer>{footer}</Styled.Footer>}
    </Styled.ScoreOverviewWrapper>
  );
};

export default ScoreOverview;
